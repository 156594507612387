@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Cores padronizadas pelo Flui Design System v2.0 */
/* ===== PRIMITIVE TOKENS ===== */
/* Accent */
/* Aurora */
/* Beach */
/* Brand */
/* cloud */
/* Contrast */
/* Dawn */
/* Desert */
/* Green */
/* Ocean */
/* Pistachio */
/* Purple */
/* Rainbow */
/* Red */
/* Storm */
/* Sunset */
/* Sunshine */
/* Twilight */
/* Yellow */
/* ===== STYLESHEETS ===== */
/* alert (yellow) */
/* danger (red) */
/* informative (purple) */
/* neutral (cloud) */
/* success (green) */
/* Sombras padronizadas pelo Flui Design System */
/* https://www.figma.com/file/l6nCPIsB7jzktVs9hAK2pB/Flui?node-id=7420%3A10 */
/* LIGHT */
/* DARK */
/* Tipografia, Fonts e configurações padronizadas pelo Flui Design System */
/* https://www.figma.com/file/l6nCPIsB7jzktVs9hAK2pB/Flui?node-id=7420%3A9 */
/* FONT FAMILIES */
/* FONT WEIGHTS */
/* FONT SIZES - 16px based */
/*
  Estilização para aplicar a identidade Flui no scrollbar nativo
*/
.flui-scrollbar {
  /* for Chrome and Edge (chromium) */
  /* for Firefox */
  scrollbar-color: var(--accent500) var(--cloud100);
  scrollbar-width: thin;
  /* for IE11 */
  scrollbar-face-color: var(--accent500);
  scrollbar-arrow-color: var(--accent500);
  scrollbar-track-color: var(--cloud100);
  scrollbar-shadow-color: var(--accent500);
  scrollbar-highlight-color: var(--accent500);
  scrollbar-3dlight-color: var(--accent500);
  scrollbar-darkshadow-Color: var(--cloud100);
}
.flui-scrollbar::-webkit-scrollbar-track {
  border-radius: 6px;
  background-color: var(--cloud100);
}
.flui-scrollbar::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}
.flui-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: var(--accent500);
}

.flui-input-container .flui-input {
  -webkit-user-select: initial !important;
  user-select: initial !important;
}

/* TAGS */
* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  font-family: var(--ubuntu);
  font-size: var(--fsbase);
}

@media print {
  body {
    height: auto !important;
  }
}
a {
  text-decoration: none;
  color: var(--accent500);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--montserrat);
}

/* CUSTOM SELECTORS */
[role=button],
[role=link] {
  cursor: pointer;
}

/* GENERAL CLASSES */
.hidden-element {
  display: none;
}

.no-margin {
  margin: 0;
}

.no-padding {
  padding: 0;
}

.wrapper-container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 var(--sp24);
}

.page-container {
  max-width: 1240px;
  margin: auto;
}

.internal-pages {
  background: var(--cloud100);
  padding-bottom: 40px;
}

.onlysr {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.grecaptcha-badge {
  display: none;
}

#pix-credentials-table-pdf {
  border: var(--sp1) solid var(--cloud300);
  border-radius: var(--sp8);
  padding: var(--sp16);
}
#pix-credentials-table-pdf .table {
  border-collapse: collapse;
  width: 100%;
  text-align: left;
}
#pix-credentials-table-pdf .table .col1 {
  width: 9%;
}
#pix-credentials-table-pdf .table .col2 {
  width: 22%;
}
#pix-credentials-table-pdf .table .col5 {
  width: 23%;
}
#pix-credentials-table-pdf .table .col4,
#pix-credentials-table-pdf .table .col5 {
  width: 23%;
}
#pix-credentials-table-pdf .table .head-row {
  border-bottom: var(--sp1) solid var(--cloud400);
}
#pix-credentials-table-pdf .table .head-row .head {
  padding: var(--sp24) var(--sp16);
  font-size: var(--fs14);
  font-family: var(--ubuntu);
  color: var(--cloud500);
}
#pix-credentials-table-pdf .table .row {
  border-top: var(--sp1) solid var(--cloud300);
  font-size: var(--fs14);
}
#pix-credentials-table-pdf .table .row .cell1,
#pix-credentials-table-pdf .table .row .cell2,
#pix-credentials-table-pdf .table .row .cell3 {
  padding: var(--sp24) var(--sp0) var(--sp24) var(--sp16);
  font-family: Arial, sans-serif;
  word-break: break-all;
  color: var(--cloud800);
}
#pix-credentials-table-pdf .table .row .cell4,
#pix-credentials-table-pdf .table .row .cell5 {
  padding: var(--sp24) var(--sp0) var(--sp24) var(--sp16);
  font-family: Arial, sans-serif;
  font-weight: var(--bold);
  word-break: break-all;
  color: var(--green500);
}

#ecommerce-credentials-table-pdf {
  border: var(--sp1) solid var(--cloud300);
  border-radius: var(--sp8);
  padding: var(--sp16);
}
#ecommerce-credentials-table-pdf .table {
  border-collapse: collapse;
  width: 100%;
  text-align: left;
}
#ecommerce-credentials-table-pdf .table .col1 {
  width: 38%;
}
#ecommerce-credentials-table-pdf .table .col2 {
  width: 0%;
}
#ecommerce-credentials-table-pdf .table .col3,
#ecommerce-credentials-table-pdf .table .col4 {
  width: 31%;
}
#ecommerce-credentials-table-pdf .table .head-row {
  border-bottom: var(--sp1) solid var(--cloud500);
}
#ecommerce-credentials-table-pdf .table .head-row .head {
  padding: var(--sp24) var(--sp16);
  font-size: var(--fs14);
  font-family: var(--ubuntu);
  color: var(--cloud600);
}
#ecommerce-credentials-table-pdf .table .row {
  border-top: var(--sp1) solid var(--cloud300);
  font-size: var(--fs14);
}
#ecommerce-credentials-table-pdf .table .row .cell1,
#ecommerce-credentials-table-pdf .table .row .cell2 {
  padding: var(--sp24) var(--sp0) var(--sp24) var(--sp16);
  font-family: var(--ubuntu);
  word-break: break-all;
  color: var(--cloud900);
}
#ecommerce-credentials-table-pdf .table .row .cell3,
#ecommerce-credentials-table-pdf .table .row .cell4 {
  padding: var(--sp24) var(--sp0) var(--sp24) var(--sp16);
  font-family: var(--ubuntu);
  font-weight: var(--bold);
  word-break: break-all;
  color: var(--green600);
}

/* Cores padronizadas pelo Flui Design System v2.0 */
/* ===== PRIMITIVE TOKENS ===== */
/* Accent */
/* Aurora */
/* Beach */
/* Brand */
/* cloud */
/* Contrast */
/* Dawn */
/* Desert */
/* Green */
/* Ocean */
/* Pistachio */
/* Purple */
/* Rainbow */
/* Red */
/* Storm */
/* Sunset */
/* Sunshine */
/* Twilight */
/* Yellow */
/* ===== STYLESHEETS ===== */
/* alert (yellow) */
/* danger (red) */
/* informative (purple) */
/* neutral (cloud) */
/* success (green) */
/* Sombras padronizadas pelo Flui Design System */
/* https://www.figma.com/file/l6nCPIsB7jzktVs9hAK2pB/Flui?node-id=7420%3A10 */
/* LIGHT */
/* DARK */
/* Tipografia, Fonts e configurações padronizadas pelo Flui Design System */
/* https://www.figma.com/file/l6nCPIsB7jzktVs9hAK2pB/Flui?node-id=7420%3A9 */
/* FONT FAMILIES */
/* FONT WEIGHTS */
/* FONT SIZES - 16px based */
/*
  Estilização para aplicar a identidade Flui no scrollbar nativo
*/
.flui-scrollbar {
  /* for Chrome and Edge (chromium) */
  /* for Firefox */
  scrollbar-color: var(--accent500) var(--cloud100);
  scrollbar-width: thin;
  /* for IE11 */
  scrollbar-face-color: var(--accent500);
  scrollbar-arrow-color: var(--accent500);
  scrollbar-track-color: var(--cloud100);
  scrollbar-shadow-color: var(--accent500);
  scrollbar-highlight-color: var(--accent500);
  scrollbar-3dlight-color: var(--accent500);
  scrollbar-darkshadow-Color: var(--cloud100);
}
.flui-scrollbar::-webkit-scrollbar-track {
  border-radius: 6px;
  background-color: var(--cloud100);
}
.flui-scrollbar::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}
.flui-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: var(--accent500);
}

.p-1 {
  padding: var(--sp1);
}

.m-1 {
  margin: var(--sp1);
}

.p-2 {
  padding: var(--sp2);
}

.m-2 {
  margin: var(--sp2);
}

.p-3 {
  padding: var(--sp3);
}

.m-3 {
  margin: var(--sp3);
}

.p-4 {
  padding: var(--sp4);
}

.m-4 {
  margin: var(--sp4);
}

.p-5 {
  padding: var(--sp5);
}

.m-5 {
  margin: var(--sp5);
}

.p-6 {
  padding: var(--sp6);
}

.m-6 {
  margin: var(--sp6);
}

.p-7 {
  padding: var(--sp7);
}

.m-7 {
  margin: var(--sp7);
}

.p-8 {
  padding: var(--sp8);
}

.m-8 {
  margin: var(--sp8);
}

.p-9 {
  padding: var(--sp9);
}

.m-9 {
  margin: var(--sp9);
}

.p-10 {
  padding: var(--sp10);
}

.m-10 {
  margin: var(--sp10);
}

.p-11 {
  padding: var(--sp11);
}

.m-11 {
  margin: var(--sp11);
}

.p-12 {
  padding: var(--sp12);
}

.m-12 {
  margin: var(--sp12);
}

.p-13 {
  padding: var(--sp13);
}

.m-13 {
  margin: var(--sp13);
}

.p-14 {
  padding: var(--sp14);
}

.m-14 {
  margin: var(--sp14);
}

.p-15 {
  padding: var(--sp15);
}

.m-15 {
  margin: var(--sp15);
}

.p-16 {
  padding: var(--sp16);
}

.m-16 {
  margin: var(--sp16);
}

.p-17 {
  padding: var(--sp17);
}

.m-17 {
  margin: var(--sp17);
}

.p-18 {
  padding: var(--sp18);
}

.m-18 {
  margin: var(--sp18);
}

.p-19 {
  padding: var(--sp19);
}

.m-19 {
  margin: var(--sp19);
}

.p-20 {
  padding: var(--sp20);
}

.m-20 {
  margin: var(--sp20);
}

.p-21 {
  padding: var(--sp21);
}

.m-21 {
  margin: var(--sp21);
}

.p-22 {
  padding: var(--sp22);
}

.m-22 {
  margin: var(--sp22);
}

.p-23 {
  padding: var(--sp23);
}

.m-23 {
  margin: var(--sp23);
}

.p-24 {
  padding: var(--sp24);
}

.m-24 {
  margin: var(--sp24);
}

.p-25 {
  padding: var(--sp25);
}

.m-25 {
  margin: var(--sp25);
}

.p-26 {
  padding: var(--sp26);
}

.m-26 {
  margin: var(--sp26);
}

.p-27 {
  padding: var(--sp27);
}

.m-27 {
  margin: var(--sp27);
}

.p-28 {
  padding: var(--sp28);
}

.m-28 {
  margin: var(--sp28);
}

.p-29 {
  padding: var(--sp29);
}

.m-29 {
  margin: var(--sp29);
}

.p-30 {
  padding: var(--sp30);
}

.m-30 {
  margin: var(--sp30);
}

.p-31 {
  padding: var(--sp31);
}

.m-31 {
  margin: var(--sp31);
}

.p-32 {
  padding: var(--sp32);
}

.m-32 {
  margin: var(--sp32);
}

.p-33 {
  padding: var(--sp33);
}

.m-33 {
  margin: var(--sp33);
}

.p-34 {
  padding: var(--sp34);
}

.m-34 {
  margin: var(--sp34);
}

.p-35 {
  padding: var(--sp35);
}

.m-35 {
  margin: var(--sp35);
}

.p-36 {
  padding: var(--sp36);
}

.m-36 {
  margin: var(--sp36);
}

.p-37 {
  padding: var(--sp37);
}

.m-37 {
  margin: var(--sp37);
}

.p-38 {
  padding: var(--sp38);
}

.m-38 {
  margin: var(--sp38);
}

.p-39 {
  padding: var(--sp39);
}

.m-39 {
  margin: var(--sp39);
}

.p-40 {
  padding: var(--sp40);
}

.m-40 {
  margin: var(--sp40);
}

.p-41 {
  padding: var(--sp41);
}

.m-41 {
  margin: var(--sp41);
}

.p-42 {
  padding: var(--sp42);
}

.m-42 {
  margin: var(--sp42);
}

.p-43 {
  padding: var(--sp43);
}

.m-43 {
  margin: var(--sp43);
}

.p-44 {
  padding: var(--sp44);
}

.m-44 {
  margin: var(--sp44);
}

.p-45 {
  padding: var(--sp45);
}

.m-45 {
  margin: var(--sp45);
}

.p-46 {
  padding: var(--sp46);
}

.m-46 {
  margin: var(--sp46);
}

.p-47 {
  padding: var(--sp47);
}

.m-47 {
  margin: var(--sp47);
}

.p-48 {
  padding: var(--sp48);
}

.m-48 {
  margin: var(--sp48);
}

.p-49 {
  padding: var(--sp49);
}

.m-49 {
  margin: var(--sp49);
}

.p-50 {
  padding: var(--sp50);
}

.m-50 {
  margin: var(--sp50);
}

.p-51 {
  padding: var(--sp51);
}

.m-51 {
  margin: var(--sp51);
}

.p-52 {
  padding: var(--sp52);
}

.m-52 {
  margin: var(--sp52);
}

.p-53 {
  padding: var(--sp53);
}

.m-53 {
  margin: var(--sp53);
}

.p-54 {
  padding: var(--sp54);
}

.m-54 {
  margin: var(--sp54);
}

.p-55 {
  padding: var(--sp55);
}

.m-55 {
  margin: var(--sp55);
}

.p-56 {
  padding: var(--sp56);
}

.m-56 {
  margin: var(--sp56);
}

.p-57 {
  padding: var(--sp57);
}

.m-57 {
  margin: var(--sp57);
}

.p-58 {
  padding: var(--sp58);
}

.m-58 {
  margin: var(--sp58);
}

.p-59 {
  padding: var(--sp59);
}

.m-59 {
  margin: var(--sp59);
}

.p-60 {
  padding: var(--sp60);
}

.m-60 {
  margin: var(--sp60);
}

.p-61 {
  padding: var(--sp61);
}

.m-61 {
  margin: var(--sp61);
}

.p-62 {
  padding: var(--sp62);
}

.m-62 {
  margin: var(--sp62);
}

.p-63 {
  padding: var(--sp63);
}

.m-63 {
  margin: var(--sp63);
}

.p-64 {
  padding: var(--sp64);
}

.m-64 {
  margin: var(--sp64);
}

.p-65 {
  padding: var(--sp65);
}

.m-65 {
  margin: var(--sp65);
}

.p-66 {
  padding: var(--sp66);
}

.m-66 {
  margin: var(--sp66);
}

.p-67 {
  padding: var(--sp67);
}

.m-67 {
  margin: var(--sp67);
}

.p-68 {
  padding: var(--sp68);
}

.m-68 {
  margin: var(--sp68);
}

.p-69 {
  padding: var(--sp69);
}

.m-69 {
  margin: var(--sp69);
}

.p-70 {
  padding: var(--sp70);
}

.m-70 {
  margin: var(--sp70);
}

.p-71 {
  padding: var(--sp71);
}

.m-71 {
  margin: var(--sp71);
}

.p-72 {
  padding: var(--sp72);
}

.m-72 {
  margin: var(--sp72);
}

.p-73 {
  padding: var(--sp73);
}

.m-73 {
  margin: var(--sp73);
}

.p-74 {
  padding: var(--sp74);
}

.m-74 {
  margin: var(--sp74);
}

.p-75 {
  padding: var(--sp75);
}

.m-75 {
  margin: var(--sp75);
}

.p-76 {
  padding: var(--sp76);
}

.m-76 {
  margin: var(--sp76);
}

.p-77 {
  padding: var(--sp77);
}

.m-77 {
  margin: var(--sp77);
}

.p-78 {
  padding: var(--sp78);
}

.m-78 {
  margin: var(--sp78);
}

.p-79 {
  padding: var(--sp79);
}

.m-79 {
  margin: var(--sp79);
}

.p-80 {
  padding: var(--sp80);
}

.m-80 {
  margin: var(--sp80);
}

.p-81 {
  padding: var(--sp81);
}

.m-81 {
  margin: var(--sp81);
}

.p-82 {
  padding: var(--sp82);
}

.m-82 {
  margin: var(--sp82);
}

.p-83 {
  padding: var(--sp83);
}

.m-83 {
  margin: var(--sp83);
}

.p-84 {
  padding: var(--sp84);
}

.m-84 {
  margin: var(--sp84);
}

.p-85 {
  padding: var(--sp85);
}

.m-85 {
  margin: var(--sp85);
}

.p-86 {
  padding: var(--sp86);
}

.m-86 {
  margin: var(--sp86);
}

.p-87 {
  padding: var(--sp87);
}

.m-87 {
  margin: var(--sp87);
}

.p-88 {
  padding: var(--sp88);
}

.m-88 {
  margin: var(--sp88);
}

.p-89 {
  padding: var(--sp89);
}

.m-89 {
  margin: var(--sp89);
}

.p-90 {
  padding: var(--sp90);
}

.m-90 {
  margin: var(--sp90);
}

.p-91 {
  padding: var(--sp91);
}

.m-91 {
  margin: var(--sp91);
}

.p-92 {
  padding: var(--sp92);
}

.m-92 {
  margin: var(--sp92);
}

.p-93 {
  padding: var(--sp93);
}

.m-93 {
  margin: var(--sp93);
}

.p-94 {
  padding: var(--sp94);
}

.m-94 {
  margin: var(--sp94);
}

.p-95 {
  padding: var(--sp95);
}

.m-95 {
  margin: var(--sp95);
}

.p-96 {
  padding: var(--sp96);
}

.m-96 {
  margin: var(--sp96);
}

.pt-1 {
  padding-top: var(--sp1);
}

.mt-1 {
  margin-top: var(--sp1);
}

.pt-2 {
  padding-top: var(--sp2);
}

.mt-2 {
  margin-top: var(--sp2);
}

.pt-3 {
  padding-top: var(--sp3);
}

.mt-3 {
  margin-top: var(--sp3);
}

.pt-4 {
  padding-top: var(--sp4);
}

.mt-4 {
  margin-top: var(--sp4);
}

.pt-5 {
  padding-top: var(--sp5);
}

.mt-5 {
  margin-top: var(--sp5);
}

.pt-6 {
  padding-top: var(--sp6);
}

.mt-6 {
  margin-top: var(--sp6);
}

.pt-7 {
  padding-top: var(--sp7);
}

.mt-7 {
  margin-top: var(--sp7);
}

.pt-8 {
  padding-top: var(--sp8);
}

.mt-8 {
  margin-top: var(--sp8);
}

.pt-9 {
  padding-top: var(--sp9);
}

.mt-9 {
  margin-top: var(--sp9);
}

.pt-10 {
  padding-top: var(--sp10);
}

.mt-10 {
  margin-top: var(--sp10);
}

.pt-11 {
  padding-top: var(--sp11);
}

.mt-11 {
  margin-top: var(--sp11);
}

.pt-12 {
  padding-top: var(--sp12);
}

.mt-12 {
  margin-top: var(--sp12);
}

.pt-13 {
  padding-top: var(--sp13);
}

.mt-13 {
  margin-top: var(--sp13);
}

.pt-14 {
  padding-top: var(--sp14);
}

.mt-14 {
  margin-top: var(--sp14);
}

.pt-15 {
  padding-top: var(--sp15);
}

.mt-15 {
  margin-top: var(--sp15);
}

.pt-16 {
  padding-top: var(--sp16);
}

.mt-16 {
  margin-top: var(--sp16);
}

.pt-17 {
  padding-top: var(--sp17);
}

.mt-17 {
  margin-top: var(--sp17);
}

.pt-18 {
  padding-top: var(--sp18);
}

.mt-18 {
  margin-top: var(--sp18);
}

.pt-19 {
  padding-top: var(--sp19);
}

.mt-19 {
  margin-top: var(--sp19);
}

.pt-20 {
  padding-top: var(--sp20);
}

.mt-20 {
  margin-top: var(--sp20);
}

.pt-21 {
  padding-top: var(--sp21);
}

.mt-21 {
  margin-top: var(--sp21);
}

.pt-22 {
  padding-top: var(--sp22);
}

.mt-22 {
  margin-top: var(--sp22);
}

.pt-23 {
  padding-top: var(--sp23);
}

.mt-23 {
  margin-top: var(--sp23);
}

.pt-24 {
  padding-top: var(--sp24);
}

.mt-24 {
  margin-top: var(--sp24);
}

.pt-25 {
  padding-top: var(--sp25);
}

.mt-25 {
  margin-top: var(--sp25);
}

.pt-26 {
  padding-top: var(--sp26);
}

.mt-26 {
  margin-top: var(--sp26);
}

.pt-27 {
  padding-top: var(--sp27);
}

.mt-27 {
  margin-top: var(--sp27);
}

.pt-28 {
  padding-top: var(--sp28);
}

.mt-28 {
  margin-top: var(--sp28);
}

.pt-29 {
  padding-top: var(--sp29);
}

.mt-29 {
  margin-top: var(--sp29);
}

.pt-30 {
  padding-top: var(--sp30);
}

.mt-30 {
  margin-top: var(--sp30);
}

.pt-31 {
  padding-top: var(--sp31);
}

.mt-31 {
  margin-top: var(--sp31);
}

.pt-32 {
  padding-top: var(--sp32);
}

.mt-32 {
  margin-top: var(--sp32);
}

.pt-33 {
  padding-top: var(--sp33);
}

.mt-33 {
  margin-top: var(--sp33);
}

.pt-34 {
  padding-top: var(--sp34);
}

.mt-34 {
  margin-top: var(--sp34);
}

.pt-35 {
  padding-top: var(--sp35);
}

.mt-35 {
  margin-top: var(--sp35);
}

.pt-36 {
  padding-top: var(--sp36);
}

.mt-36 {
  margin-top: var(--sp36);
}

.pt-37 {
  padding-top: var(--sp37);
}

.mt-37 {
  margin-top: var(--sp37);
}

.pt-38 {
  padding-top: var(--sp38);
}

.mt-38 {
  margin-top: var(--sp38);
}

.pt-39 {
  padding-top: var(--sp39);
}

.mt-39 {
  margin-top: var(--sp39);
}

.pt-40 {
  padding-top: var(--sp40);
}

.mt-40 {
  margin-top: var(--sp40);
}

.pt-41 {
  padding-top: var(--sp41);
}

.mt-41 {
  margin-top: var(--sp41);
}

.pt-42 {
  padding-top: var(--sp42);
}

.mt-42 {
  margin-top: var(--sp42);
}

.pt-43 {
  padding-top: var(--sp43);
}

.mt-43 {
  margin-top: var(--sp43);
}

.pt-44 {
  padding-top: var(--sp44);
}

.mt-44 {
  margin-top: var(--sp44);
}

.pt-45 {
  padding-top: var(--sp45);
}

.mt-45 {
  margin-top: var(--sp45);
}

.pt-46 {
  padding-top: var(--sp46);
}

.mt-46 {
  margin-top: var(--sp46);
}

.pt-47 {
  padding-top: var(--sp47);
}

.mt-47 {
  margin-top: var(--sp47);
}

.pt-48 {
  padding-top: var(--sp48);
}

.mt-48 {
  margin-top: var(--sp48);
}

.pt-49 {
  padding-top: var(--sp49);
}

.mt-49 {
  margin-top: var(--sp49);
}

.pt-50 {
  padding-top: var(--sp50);
}

.mt-50 {
  margin-top: var(--sp50);
}

.pt-51 {
  padding-top: var(--sp51);
}

.mt-51 {
  margin-top: var(--sp51);
}

.pt-52 {
  padding-top: var(--sp52);
}

.mt-52 {
  margin-top: var(--sp52);
}

.pt-53 {
  padding-top: var(--sp53);
}

.mt-53 {
  margin-top: var(--sp53);
}

.pt-54 {
  padding-top: var(--sp54);
}

.mt-54 {
  margin-top: var(--sp54);
}

.pt-55 {
  padding-top: var(--sp55);
}

.mt-55 {
  margin-top: var(--sp55);
}

.pt-56 {
  padding-top: var(--sp56);
}

.mt-56 {
  margin-top: var(--sp56);
}

.pt-57 {
  padding-top: var(--sp57);
}

.mt-57 {
  margin-top: var(--sp57);
}

.pt-58 {
  padding-top: var(--sp58);
}

.mt-58 {
  margin-top: var(--sp58);
}

.pt-59 {
  padding-top: var(--sp59);
}

.mt-59 {
  margin-top: var(--sp59);
}

.pt-60 {
  padding-top: var(--sp60);
}

.mt-60 {
  margin-top: var(--sp60);
}

.pt-61 {
  padding-top: var(--sp61);
}

.mt-61 {
  margin-top: var(--sp61);
}

.pt-62 {
  padding-top: var(--sp62);
}

.mt-62 {
  margin-top: var(--sp62);
}

.pt-63 {
  padding-top: var(--sp63);
}

.mt-63 {
  margin-top: var(--sp63);
}

.pt-64 {
  padding-top: var(--sp64);
}

.mt-64 {
  margin-top: var(--sp64);
}

.pt-65 {
  padding-top: var(--sp65);
}

.mt-65 {
  margin-top: var(--sp65);
}

.pt-66 {
  padding-top: var(--sp66);
}

.mt-66 {
  margin-top: var(--sp66);
}

.pt-67 {
  padding-top: var(--sp67);
}

.mt-67 {
  margin-top: var(--sp67);
}

.pt-68 {
  padding-top: var(--sp68);
}

.mt-68 {
  margin-top: var(--sp68);
}

.pt-69 {
  padding-top: var(--sp69);
}

.mt-69 {
  margin-top: var(--sp69);
}

.pt-70 {
  padding-top: var(--sp70);
}

.mt-70 {
  margin-top: var(--sp70);
}

.pt-71 {
  padding-top: var(--sp71);
}

.mt-71 {
  margin-top: var(--sp71);
}

.pt-72 {
  padding-top: var(--sp72);
}

.mt-72 {
  margin-top: var(--sp72);
}

.pt-73 {
  padding-top: var(--sp73);
}

.mt-73 {
  margin-top: var(--sp73);
}

.pt-74 {
  padding-top: var(--sp74);
}

.mt-74 {
  margin-top: var(--sp74);
}

.pt-75 {
  padding-top: var(--sp75);
}

.mt-75 {
  margin-top: var(--sp75);
}

.pt-76 {
  padding-top: var(--sp76);
}

.mt-76 {
  margin-top: var(--sp76);
}

.pt-77 {
  padding-top: var(--sp77);
}

.mt-77 {
  margin-top: var(--sp77);
}

.pt-78 {
  padding-top: var(--sp78);
}

.mt-78 {
  margin-top: var(--sp78);
}

.pt-79 {
  padding-top: var(--sp79);
}

.mt-79 {
  margin-top: var(--sp79);
}

.pt-80 {
  padding-top: var(--sp80);
}

.mt-80 {
  margin-top: var(--sp80);
}

.pt-81 {
  padding-top: var(--sp81);
}

.mt-81 {
  margin-top: var(--sp81);
}

.pt-82 {
  padding-top: var(--sp82);
}

.mt-82 {
  margin-top: var(--sp82);
}

.pt-83 {
  padding-top: var(--sp83);
}

.mt-83 {
  margin-top: var(--sp83);
}

.pt-84 {
  padding-top: var(--sp84);
}

.mt-84 {
  margin-top: var(--sp84);
}

.pt-85 {
  padding-top: var(--sp85);
}

.mt-85 {
  margin-top: var(--sp85);
}

.pt-86 {
  padding-top: var(--sp86);
}

.mt-86 {
  margin-top: var(--sp86);
}

.pt-87 {
  padding-top: var(--sp87);
}

.mt-87 {
  margin-top: var(--sp87);
}

.pt-88 {
  padding-top: var(--sp88);
}

.mt-88 {
  margin-top: var(--sp88);
}

.pt-89 {
  padding-top: var(--sp89);
}

.mt-89 {
  margin-top: var(--sp89);
}

.pt-90 {
  padding-top: var(--sp90);
}

.mt-90 {
  margin-top: var(--sp90);
}

.pt-91 {
  padding-top: var(--sp91);
}

.mt-91 {
  margin-top: var(--sp91);
}

.pt-92 {
  padding-top: var(--sp92);
}

.mt-92 {
  margin-top: var(--sp92);
}

.pt-93 {
  padding-top: var(--sp93);
}

.mt-93 {
  margin-top: var(--sp93);
}

.pt-94 {
  padding-top: var(--sp94);
}

.mt-94 {
  margin-top: var(--sp94);
}

.pt-95 {
  padding-top: var(--sp95);
}

.mt-95 {
  margin-top: var(--sp95);
}

.pt-96 {
  padding-top: var(--sp96);
}

.mt-96 {
  margin-top: var(--sp96);
}

.pr-1 {
  padding-right: var(--sp1);
}

.mr-1 {
  margin-right: var(--sp1);
}

.pr-2 {
  padding-right: var(--sp2);
}

.mr-2 {
  margin-right: var(--sp2);
}

.pr-3 {
  padding-right: var(--sp3);
}

.mr-3 {
  margin-right: var(--sp3);
}

.pr-4 {
  padding-right: var(--sp4);
}

.mr-4 {
  margin-right: var(--sp4);
}

.pr-5 {
  padding-right: var(--sp5);
}

.mr-5 {
  margin-right: var(--sp5);
}

.pr-6 {
  padding-right: var(--sp6);
}

.mr-6 {
  margin-right: var(--sp6);
}

.pr-7 {
  padding-right: var(--sp7);
}

.mr-7 {
  margin-right: var(--sp7);
}

.pr-8 {
  padding-right: var(--sp8);
}

.mr-8 {
  margin-right: var(--sp8);
}

.pr-9 {
  padding-right: var(--sp9);
}

.mr-9 {
  margin-right: var(--sp9);
}

.pr-10 {
  padding-right: var(--sp10);
}

.mr-10 {
  margin-right: var(--sp10);
}

.pr-11 {
  padding-right: var(--sp11);
}

.mr-11 {
  margin-right: var(--sp11);
}

.pr-12 {
  padding-right: var(--sp12);
}

.mr-12 {
  margin-right: var(--sp12);
}

.pr-13 {
  padding-right: var(--sp13);
}

.mr-13 {
  margin-right: var(--sp13);
}

.pr-14 {
  padding-right: var(--sp14);
}

.mr-14 {
  margin-right: var(--sp14);
}

.pr-15 {
  padding-right: var(--sp15);
}

.mr-15 {
  margin-right: var(--sp15);
}

.pr-16 {
  padding-right: var(--sp16);
}

.mr-16 {
  margin-right: var(--sp16);
}

.pr-17 {
  padding-right: var(--sp17);
}

.mr-17 {
  margin-right: var(--sp17);
}

.pr-18 {
  padding-right: var(--sp18);
}

.mr-18 {
  margin-right: var(--sp18);
}

.pr-19 {
  padding-right: var(--sp19);
}

.mr-19 {
  margin-right: var(--sp19);
}

.pr-20 {
  padding-right: var(--sp20);
}

.mr-20 {
  margin-right: var(--sp20);
}

.pr-21 {
  padding-right: var(--sp21);
}

.mr-21 {
  margin-right: var(--sp21);
}

.pr-22 {
  padding-right: var(--sp22);
}

.mr-22 {
  margin-right: var(--sp22);
}

.pr-23 {
  padding-right: var(--sp23);
}

.mr-23 {
  margin-right: var(--sp23);
}

.pr-24 {
  padding-right: var(--sp24);
}

.mr-24 {
  margin-right: var(--sp24);
}

.pr-25 {
  padding-right: var(--sp25);
}

.mr-25 {
  margin-right: var(--sp25);
}

.pr-26 {
  padding-right: var(--sp26);
}

.mr-26 {
  margin-right: var(--sp26);
}

.pr-27 {
  padding-right: var(--sp27);
}

.mr-27 {
  margin-right: var(--sp27);
}

.pr-28 {
  padding-right: var(--sp28);
}

.mr-28 {
  margin-right: var(--sp28);
}

.pr-29 {
  padding-right: var(--sp29);
}

.mr-29 {
  margin-right: var(--sp29);
}

.pr-30 {
  padding-right: var(--sp30);
}

.mr-30 {
  margin-right: var(--sp30);
}

.pr-31 {
  padding-right: var(--sp31);
}

.mr-31 {
  margin-right: var(--sp31);
}

.pr-32 {
  padding-right: var(--sp32);
}

.mr-32 {
  margin-right: var(--sp32);
}

.pr-33 {
  padding-right: var(--sp33);
}

.mr-33 {
  margin-right: var(--sp33);
}

.pr-34 {
  padding-right: var(--sp34);
}

.mr-34 {
  margin-right: var(--sp34);
}

.pr-35 {
  padding-right: var(--sp35);
}

.mr-35 {
  margin-right: var(--sp35);
}

.pr-36 {
  padding-right: var(--sp36);
}

.mr-36 {
  margin-right: var(--sp36);
}

.pr-37 {
  padding-right: var(--sp37);
}

.mr-37 {
  margin-right: var(--sp37);
}

.pr-38 {
  padding-right: var(--sp38);
}

.mr-38 {
  margin-right: var(--sp38);
}

.pr-39 {
  padding-right: var(--sp39);
}

.mr-39 {
  margin-right: var(--sp39);
}

.pr-40 {
  padding-right: var(--sp40);
}

.mr-40 {
  margin-right: var(--sp40);
}

.pr-41 {
  padding-right: var(--sp41);
}

.mr-41 {
  margin-right: var(--sp41);
}

.pr-42 {
  padding-right: var(--sp42);
}

.mr-42 {
  margin-right: var(--sp42);
}

.pr-43 {
  padding-right: var(--sp43);
}

.mr-43 {
  margin-right: var(--sp43);
}

.pr-44 {
  padding-right: var(--sp44);
}

.mr-44 {
  margin-right: var(--sp44);
}

.pr-45 {
  padding-right: var(--sp45);
}

.mr-45 {
  margin-right: var(--sp45);
}

.pr-46 {
  padding-right: var(--sp46);
}

.mr-46 {
  margin-right: var(--sp46);
}

.pr-47 {
  padding-right: var(--sp47);
}

.mr-47 {
  margin-right: var(--sp47);
}

.pr-48 {
  padding-right: var(--sp48);
}

.mr-48 {
  margin-right: var(--sp48);
}

.pr-49 {
  padding-right: var(--sp49);
}

.mr-49 {
  margin-right: var(--sp49);
}

.pr-50 {
  padding-right: var(--sp50);
}

.mr-50 {
  margin-right: var(--sp50);
}

.pr-51 {
  padding-right: var(--sp51);
}

.mr-51 {
  margin-right: var(--sp51);
}

.pr-52 {
  padding-right: var(--sp52);
}

.mr-52 {
  margin-right: var(--sp52);
}

.pr-53 {
  padding-right: var(--sp53);
}

.mr-53 {
  margin-right: var(--sp53);
}

.pr-54 {
  padding-right: var(--sp54);
}

.mr-54 {
  margin-right: var(--sp54);
}

.pr-55 {
  padding-right: var(--sp55);
}

.mr-55 {
  margin-right: var(--sp55);
}

.pr-56 {
  padding-right: var(--sp56);
}

.mr-56 {
  margin-right: var(--sp56);
}

.pr-57 {
  padding-right: var(--sp57);
}

.mr-57 {
  margin-right: var(--sp57);
}

.pr-58 {
  padding-right: var(--sp58);
}

.mr-58 {
  margin-right: var(--sp58);
}

.pr-59 {
  padding-right: var(--sp59);
}

.mr-59 {
  margin-right: var(--sp59);
}

.pr-60 {
  padding-right: var(--sp60);
}

.mr-60 {
  margin-right: var(--sp60);
}

.pr-61 {
  padding-right: var(--sp61);
}

.mr-61 {
  margin-right: var(--sp61);
}

.pr-62 {
  padding-right: var(--sp62);
}

.mr-62 {
  margin-right: var(--sp62);
}

.pr-63 {
  padding-right: var(--sp63);
}

.mr-63 {
  margin-right: var(--sp63);
}

.pr-64 {
  padding-right: var(--sp64);
}

.mr-64 {
  margin-right: var(--sp64);
}

.pr-65 {
  padding-right: var(--sp65);
}

.mr-65 {
  margin-right: var(--sp65);
}

.pr-66 {
  padding-right: var(--sp66);
}

.mr-66 {
  margin-right: var(--sp66);
}

.pr-67 {
  padding-right: var(--sp67);
}

.mr-67 {
  margin-right: var(--sp67);
}

.pr-68 {
  padding-right: var(--sp68);
}

.mr-68 {
  margin-right: var(--sp68);
}

.pr-69 {
  padding-right: var(--sp69);
}

.mr-69 {
  margin-right: var(--sp69);
}

.pr-70 {
  padding-right: var(--sp70);
}

.mr-70 {
  margin-right: var(--sp70);
}

.pr-71 {
  padding-right: var(--sp71);
}

.mr-71 {
  margin-right: var(--sp71);
}

.pr-72 {
  padding-right: var(--sp72);
}

.mr-72 {
  margin-right: var(--sp72);
}

.pr-73 {
  padding-right: var(--sp73);
}

.mr-73 {
  margin-right: var(--sp73);
}

.pr-74 {
  padding-right: var(--sp74);
}

.mr-74 {
  margin-right: var(--sp74);
}

.pr-75 {
  padding-right: var(--sp75);
}

.mr-75 {
  margin-right: var(--sp75);
}

.pr-76 {
  padding-right: var(--sp76);
}

.mr-76 {
  margin-right: var(--sp76);
}

.pr-77 {
  padding-right: var(--sp77);
}

.mr-77 {
  margin-right: var(--sp77);
}

.pr-78 {
  padding-right: var(--sp78);
}

.mr-78 {
  margin-right: var(--sp78);
}

.pr-79 {
  padding-right: var(--sp79);
}

.mr-79 {
  margin-right: var(--sp79);
}

.pr-80 {
  padding-right: var(--sp80);
}

.mr-80 {
  margin-right: var(--sp80);
}

.pr-81 {
  padding-right: var(--sp81);
}

.mr-81 {
  margin-right: var(--sp81);
}

.pr-82 {
  padding-right: var(--sp82);
}

.mr-82 {
  margin-right: var(--sp82);
}

.pr-83 {
  padding-right: var(--sp83);
}

.mr-83 {
  margin-right: var(--sp83);
}

.pr-84 {
  padding-right: var(--sp84);
}

.mr-84 {
  margin-right: var(--sp84);
}

.pr-85 {
  padding-right: var(--sp85);
}

.mr-85 {
  margin-right: var(--sp85);
}

.pr-86 {
  padding-right: var(--sp86);
}

.mr-86 {
  margin-right: var(--sp86);
}

.pr-87 {
  padding-right: var(--sp87);
}

.mr-87 {
  margin-right: var(--sp87);
}

.pr-88 {
  padding-right: var(--sp88);
}

.mr-88 {
  margin-right: var(--sp88);
}

.pr-89 {
  padding-right: var(--sp89);
}

.mr-89 {
  margin-right: var(--sp89);
}

.pr-90 {
  padding-right: var(--sp90);
}

.mr-90 {
  margin-right: var(--sp90);
}

.pr-91 {
  padding-right: var(--sp91);
}

.mr-91 {
  margin-right: var(--sp91);
}

.pr-92 {
  padding-right: var(--sp92);
}

.mr-92 {
  margin-right: var(--sp92);
}

.pr-93 {
  padding-right: var(--sp93);
}

.mr-93 {
  margin-right: var(--sp93);
}

.pr-94 {
  padding-right: var(--sp94);
}

.mr-94 {
  margin-right: var(--sp94);
}

.pr-95 {
  padding-right: var(--sp95);
}

.mr-95 {
  margin-right: var(--sp95);
}

.pr-96 {
  padding-right: var(--sp96);
}

.mr-96 {
  margin-right: var(--sp96);
}

.pb-1 {
  padding-bottom: var(--sp1);
}

.mb-1 {
  margin-bottom: var(--sp1);
}

.pb-2 {
  padding-bottom: var(--sp2);
}

.mb-2 {
  margin-bottom: var(--sp2);
}

.pb-3 {
  padding-bottom: var(--sp3);
}

.mb-3 {
  margin-bottom: var(--sp3);
}

.pb-4 {
  padding-bottom: var(--sp4);
}

.mb-4 {
  margin-bottom: var(--sp4);
}

.pb-5 {
  padding-bottom: var(--sp5);
}

.mb-5 {
  margin-bottom: var(--sp5);
}

.pb-6 {
  padding-bottom: var(--sp6);
}

.mb-6 {
  margin-bottom: var(--sp6);
}

.pb-7 {
  padding-bottom: var(--sp7);
}

.mb-7 {
  margin-bottom: var(--sp7);
}

.pb-8 {
  padding-bottom: var(--sp8);
}

.mb-8 {
  margin-bottom: var(--sp8);
}

.pb-9 {
  padding-bottom: var(--sp9);
}

.mb-9 {
  margin-bottom: var(--sp9);
}

.pb-10 {
  padding-bottom: var(--sp10);
}

.mb-10 {
  margin-bottom: var(--sp10);
}

.pb-11 {
  padding-bottom: var(--sp11);
}

.mb-11 {
  margin-bottom: var(--sp11);
}

.pb-12 {
  padding-bottom: var(--sp12);
}

.mb-12 {
  margin-bottom: var(--sp12);
}

.pb-13 {
  padding-bottom: var(--sp13);
}

.mb-13 {
  margin-bottom: var(--sp13);
}

.pb-14 {
  padding-bottom: var(--sp14);
}

.mb-14 {
  margin-bottom: var(--sp14);
}

.pb-15 {
  padding-bottom: var(--sp15);
}

.mb-15 {
  margin-bottom: var(--sp15);
}

.pb-16 {
  padding-bottom: var(--sp16);
}

.mb-16 {
  margin-bottom: var(--sp16);
}

.pb-17 {
  padding-bottom: var(--sp17);
}

.mb-17 {
  margin-bottom: var(--sp17);
}

.pb-18 {
  padding-bottom: var(--sp18);
}

.mb-18 {
  margin-bottom: var(--sp18);
}

.pb-19 {
  padding-bottom: var(--sp19);
}

.mb-19 {
  margin-bottom: var(--sp19);
}

.pb-20 {
  padding-bottom: var(--sp20);
}

.mb-20 {
  margin-bottom: var(--sp20);
}

.pb-21 {
  padding-bottom: var(--sp21);
}

.mb-21 {
  margin-bottom: var(--sp21);
}

.pb-22 {
  padding-bottom: var(--sp22);
}

.mb-22 {
  margin-bottom: var(--sp22);
}

.pb-23 {
  padding-bottom: var(--sp23);
}

.mb-23 {
  margin-bottom: var(--sp23);
}

.pb-24 {
  padding-bottom: var(--sp24);
}

.mb-24 {
  margin-bottom: var(--sp24);
}

.pb-25 {
  padding-bottom: var(--sp25);
}

.mb-25 {
  margin-bottom: var(--sp25);
}

.pb-26 {
  padding-bottom: var(--sp26);
}

.mb-26 {
  margin-bottom: var(--sp26);
}

.pb-27 {
  padding-bottom: var(--sp27);
}

.mb-27 {
  margin-bottom: var(--sp27);
}

.pb-28 {
  padding-bottom: var(--sp28);
}

.mb-28 {
  margin-bottom: var(--sp28);
}

.pb-29 {
  padding-bottom: var(--sp29);
}

.mb-29 {
  margin-bottom: var(--sp29);
}

.pb-30 {
  padding-bottom: var(--sp30);
}

.mb-30 {
  margin-bottom: var(--sp30);
}

.pb-31 {
  padding-bottom: var(--sp31);
}

.mb-31 {
  margin-bottom: var(--sp31);
}

.pb-32 {
  padding-bottom: var(--sp32);
}

.mb-32 {
  margin-bottom: var(--sp32);
}

.pb-33 {
  padding-bottom: var(--sp33);
}

.mb-33 {
  margin-bottom: var(--sp33);
}

.pb-34 {
  padding-bottom: var(--sp34);
}

.mb-34 {
  margin-bottom: var(--sp34);
}

.pb-35 {
  padding-bottom: var(--sp35);
}

.mb-35 {
  margin-bottom: var(--sp35);
}

.pb-36 {
  padding-bottom: var(--sp36);
}

.mb-36 {
  margin-bottom: var(--sp36);
}

.pb-37 {
  padding-bottom: var(--sp37);
}

.mb-37 {
  margin-bottom: var(--sp37);
}

.pb-38 {
  padding-bottom: var(--sp38);
}

.mb-38 {
  margin-bottom: var(--sp38);
}

.pb-39 {
  padding-bottom: var(--sp39);
}

.mb-39 {
  margin-bottom: var(--sp39);
}

.pb-40 {
  padding-bottom: var(--sp40);
}

.mb-40 {
  margin-bottom: var(--sp40);
}

.pb-41 {
  padding-bottom: var(--sp41);
}

.mb-41 {
  margin-bottom: var(--sp41);
}

.pb-42 {
  padding-bottom: var(--sp42);
}

.mb-42 {
  margin-bottom: var(--sp42);
}

.pb-43 {
  padding-bottom: var(--sp43);
}

.mb-43 {
  margin-bottom: var(--sp43);
}

.pb-44 {
  padding-bottom: var(--sp44);
}

.mb-44 {
  margin-bottom: var(--sp44);
}

.pb-45 {
  padding-bottom: var(--sp45);
}

.mb-45 {
  margin-bottom: var(--sp45);
}

.pb-46 {
  padding-bottom: var(--sp46);
}

.mb-46 {
  margin-bottom: var(--sp46);
}

.pb-47 {
  padding-bottom: var(--sp47);
}

.mb-47 {
  margin-bottom: var(--sp47);
}

.pb-48 {
  padding-bottom: var(--sp48);
}

.mb-48 {
  margin-bottom: var(--sp48);
}

.pb-49 {
  padding-bottom: var(--sp49);
}

.mb-49 {
  margin-bottom: var(--sp49);
}

.pb-50 {
  padding-bottom: var(--sp50);
}

.mb-50 {
  margin-bottom: var(--sp50);
}

.pb-51 {
  padding-bottom: var(--sp51);
}

.mb-51 {
  margin-bottom: var(--sp51);
}

.pb-52 {
  padding-bottom: var(--sp52);
}

.mb-52 {
  margin-bottom: var(--sp52);
}

.pb-53 {
  padding-bottom: var(--sp53);
}

.mb-53 {
  margin-bottom: var(--sp53);
}

.pb-54 {
  padding-bottom: var(--sp54);
}

.mb-54 {
  margin-bottom: var(--sp54);
}

.pb-55 {
  padding-bottom: var(--sp55);
}

.mb-55 {
  margin-bottom: var(--sp55);
}

.pb-56 {
  padding-bottom: var(--sp56);
}

.mb-56 {
  margin-bottom: var(--sp56);
}

.pb-57 {
  padding-bottom: var(--sp57);
}

.mb-57 {
  margin-bottom: var(--sp57);
}

.pb-58 {
  padding-bottom: var(--sp58);
}

.mb-58 {
  margin-bottom: var(--sp58);
}

.pb-59 {
  padding-bottom: var(--sp59);
}

.mb-59 {
  margin-bottom: var(--sp59);
}

.pb-60 {
  padding-bottom: var(--sp60);
}

.mb-60 {
  margin-bottom: var(--sp60);
}

.pb-61 {
  padding-bottom: var(--sp61);
}

.mb-61 {
  margin-bottom: var(--sp61);
}

.pb-62 {
  padding-bottom: var(--sp62);
}

.mb-62 {
  margin-bottom: var(--sp62);
}

.pb-63 {
  padding-bottom: var(--sp63);
}

.mb-63 {
  margin-bottom: var(--sp63);
}

.pb-64 {
  padding-bottom: var(--sp64);
}

.mb-64 {
  margin-bottom: var(--sp64);
}

.pb-65 {
  padding-bottom: var(--sp65);
}

.mb-65 {
  margin-bottom: var(--sp65);
}

.pb-66 {
  padding-bottom: var(--sp66);
}

.mb-66 {
  margin-bottom: var(--sp66);
}

.pb-67 {
  padding-bottom: var(--sp67);
}

.mb-67 {
  margin-bottom: var(--sp67);
}

.pb-68 {
  padding-bottom: var(--sp68);
}

.mb-68 {
  margin-bottom: var(--sp68);
}

.pb-69 {
  padding-bottom: var(--sp69);
}

.mb-69 {
  margin-bottom: var(--sp69);
}

.pb-70 {
  padding-bottom: var(--sp70);
}

.mb-70 {
  margin-bottom: var(--sp70);
}

.pb-71 {
  padding-bottom: var(--sp71);
}

.mb-71 {
  margin-bottom: var(--sp71);
}

.pb-72 {
  padding-bottom: var(--sp72);
}

.mb-72 {
  margin-bottom: var(--sp72);
}

.pb-73 {
  padding-bottom: var(--sp73);
}

.mb-73 {
  margin-bottom: var(--sp73);
}

.pb-74 {
  padding-bottom: var(--sp74);
}

.mb-74 {
  margin-bottom: var(--sp74);
}

.pb-75 {
  padding-bottom: var(--sp75);
}

.mb-75 {
  margin-bottom: var(--sp75);
}

.pb-76 {
  padding-bottom: var(--sp76);
}

.mb-76 {
  margin-bottom: var(--sp76);
}

.pb-77 {
  padding-bottom: var(--sp77);
}

.mb-77 {
  margin-bottom: var(--sp77);
}

.pb-78 {
  padding-bottom: var(--sp78);
}

.mb-78 {
  margin-bottom: var(--sp78);
}

.pb-79 {
  padding-bottom: var(--sp79);
}

.mb-79 {
  margin-bottom: var(--sp79);
}

.pb-80 {
  padding-bottom: var(--sp80);
}

.mb-80 {
  margin-bottom: var(--sp80);
}

.pb-81 {
  padding-bottom: var(--sp81);
}

.mb-81 {
  margin-bottom: var(--sp81);
}

.pb-82 {
  padding-bottom: var(--sp82);
}

.mb-82 {
  margin-bottom: var(--sp82);
}

.pb-83 {
  padding-bottom: var(--sp83);
}

.mb-83 {
  margin-bottom: var(--sp83);
}

.pb-84 {
  padding-bottom: var(--sp84);
}

.mb-84 {
  margin-bottom: var(--sp84);
}

.pb-85 {
  padding-bottom: var(--sp85);
}

.mb-85 {
  margin-bottom: var(--sp85);
}

.pb-86 {
  padding-bottom: var(--sp86);
}

.mb-86 {
  margin-bottom: var(--sp86);
}

.pb-87 {
  padding-bottom: var(--sp87);
}

.mb-87 {
  margin-bottom: var(--sp87);
}

.pb-88 {
  padding-bottom: var(--sp88);
}

.mb-88 {
  margin-bottom: var(--sp88);
}

.pb-89 {
  padding-bottom: var(--sp89);
}

.mb-89 {
  margin-bottom: var(--sp89);
}

.pb-90 {
  padding-bottom: var(--sp90);
}

.mb-90 {
  margin-bottom: var(--sp90);
}

.pb-91 {
  padding-bottom: var(--sp91);
}

.mb-91 {
  margin-bottom: var(--sp91);
}

.pb-92 {
  padding-bottom: var(--sp92);
}

.mb-92 {
  margin-bottom: var(--sp92);
}

.pb-93 {
  padding-bottom: var(--sp93);
}

.mb-93 {
  margin-bottom: var(--sp93);
}

.pb-94 {
  padding-bottom: var(--sp94);
}

.mb-94 {
  margin-bottom: var(--sp94);
}

.pb-95 {
  padding-bottom: var(--sp95);
}

.mb-95 {
  margin-bottom: var(--sp95);
}

.pb-96 {
  padding-bottom: var(--sp96);
}

.mb-96 {
  margin-bottom: var(--sp96);
}

.pl-1 {
  padding-left: var(--sp1);
}

.ml-1 {
  margin-left: var(--sp1);
}

.pl-2 {
  padding-left: var(--sp2);
}

.ml-2 {
  margin-left: var(--sp2);
}

.pl-3 {
  padding-left: var(--sp3);
}

.ml-3 {
  margin-left: var(--sp3);
}

.pl-4 {
  padding-left: var(--sp4);
}

.ml-4 {
  margin-left: var(--sp4);
}

.pl-5 {
  padding-left: var(--sp5);
}

.ml-5 {
  margin-left: var(--sp5);
}

.pl-6 {
  padding-left: var(--sp6);
}

.ml-6 {
  margin-left: var(--sp6);
}

.pl-7 {
  padding-left: var(--sp7);
}

.ml-7 {
  margin-left: var(--sp7);
}

.pl-8 {
  padding-left: var(--sp8);
}

.ml-8 {
  margin-left: var(--sp8);
}

.pl-9 {
  padding-left: var(--sp9);
}

.ml-9 {
  margin-left: var(--sp9);
}

.pl-10 {
  padding-left: var(--sp10);
}

.ml-10 {
  margin-left: var(--sp10);
}

.pl-11 {
  padding-left: var(--sp11);
}

.ml-11 {
  margin-left: var(--sp11);
}

.pl-12 {
  padding-left: var(--sp12);
}

.ml-12 {
  margin-left: var(--sp12);
}

.pl-13 {
  padding-left: var(--sp13);
}

.ml-13 {
  margin-left: var(--sp13);
}

.pl-14 {
  padding-left: var(--sp14);
}

.ml-14 {
  margin-left: var(--sp14);
}

.pl-15 {
  padding-left: var(--sp15);
}

.ml-15 {
  margin-left: var(--sp15);
}

.pl-16 {
  padding-left: var(--sp16);
}

.ml-16 {
  margin-left: var(--sp16);
}

.pl-17 {
  padding-left: var(--sp17);
}

.ml-17 {
  margin-left: var(--sp17);
}

.pl-18 {
  padding-left: var(--sp18);
}

.ml-18 {
  margin-left: var(--sp18);
}

.pl-19 {
  padding-left: var(--sp19);
}

.ml-19 {
  margin-left: var(--sp19);
}

.pl-20 {
  padding-left: var(--sp20);
}

.ml-20 {
  margin-left: var(--sp20);
}

.pl-21 {
  padding-left: var(--sp21);
}

.ml-21 {
  margin-left: var(--sp21);
}

.pl-22 {
  padding-left: var(--sp22);
}

.ml-22 {
  margin-left: var(--sp22);
}

.pl-23 {
  padding-left: var(--sp23);
}

.ml-23 {
  margin-left: var(--sp23);
}

.pl-24 {
  padding-left: var(--sp24);
}

.ml-24 {
  margin-left: var(--sp24);
}

.pl-25 {
  padding-left: var(--sp25);
}

.ml-25 {
  margin-left: var(--sp25);
}

.pl-26 {
  padding-left: var(--sp26);
}

.ml-26 {
  margin-left: var(--sp26);
}

.pl-27 {
  padding-left: var(--sp27);
}

.ml-27 {
  margin-left: var(--sp27);
}

.pl-28 {
  padding-left: var(--sp28);
}

.ml-28 {
  margin-left: var(--sp28);
}

.pl-29 {
  padding-left: var(--sp29);
}

.ml-29 {
  margin-left: var(--sp29);
}

.pl-30 {
  padding-left: var(--sp30);
}

.ml-30 {
  margin-left: var(--sp30);
}

.pl-31 {
  padding-left: var(--sp31);
}

.ml-31 {
  margin-left: var(--sp31);
}

.pl-32 {
  padding-left: var(--sp32);
}

.ml-32 {
  margin-left: var(--sp32);
}

.pl-33 {
  padding-left: var(--sp33);
}

.ml-33 {
  margin-left: var(--sp33);
}

.pl-34 {
  padding-left: var(--sp34);
}

.ml-34 {
  margin-left: var(--sp34);
}

.pl-35 {
  padding-left: var(--sp35);
}

.ml-35 {
  margin-left: var(--sp35);
}

.pl-36 {
  padding-left: var(--sp36);
}

.ml-36 {
  margin-left: var(--sp36);
}

.pl-37 {
  padding-left: var(--sp37);
}

.ml-37 {
  margin-left: var(--sp37);
}

.pl-38 {
  padding-left: var(--sp38);
}

.ml-38 {
  margin-left: var(--sp38);
}

.pl-39 {
  padding-left: var(--sp39);
}

.ml-39 {
  margin-left: var(--sp39);
}

.pl-40 {
  padding-left: var(--sp40);
}

.ml-40 {
  margin-left: var(--sp40);
}

.pl-41 {
  padding-left: var(--sp41);
}

.ml-41 {
  margin-left: var(--sp41);
}

.pl-42 {
  padding-left: var(--sp42);
}

.ml-42 {
  margin-left: var(--sp42);
}

.pl-43 {
  padding-left: var(--sp43);
}

.ml-43 {
  margin-left: var(--sp43);
}

.pl-44 {
  padding-left: var(--sp44);
}

.ml-44 {
  margin-left: var(--sp44);
}

.pl-45 {
  padding-left: var(--sp45);
}

.ml-45 {
  margin-left: var(--sp45);
}

.pl-46 {
  padding-left: var(--sp46);
}

.ml-46 {
  margin-left: var(--sp46);
}

.pl-47 {
  padding-left: var(--sp47);
}

.ml-47 {
  margin-left: var(--sp47);
}

.pl-48 {
  padding-left: var(--sp48);
}

.ml-48 {
  margin-left: var(--sp48);
}

.pl-49 {
  padding-left: var(--sp49);
}

.ml-49 {
  margin-left: var(--sp49);
}

.pl-50 {
  padding-left: var(--sp50);
}

.ml-50 {
  margin-left: var(--sp50);
}

.pl-51 {
  padding-left: var(--sp51);
}

.ml-51 {
  margin-left: var(--sp51);
}

.pl-52 {
  padding-left: var(--sp52);
}

.ml-52 {
  margin-left: var(--sp52);
}

.pl-53 {
  padding-left: var(--sp53);
}

.ml-53 {
  margin-left: var(--sp53);
}

.pl-54 {
  padding-left: var(--sp54);
}

.ml-54 {
  margin-left: var(--sp54);
}

.pl-55 {
  padding-left: var(--sp55);
}

.ml-55 {
  margin-left: var(--sp55);
}

.pl-56 {
  padding-left: var(--sp56);
}

.ml-56 {
  margin-left: var(--sp56);
}

.pl-57 {
  padding-left: var(--sp57);
}

.ml-57 {
  margin-left: var(--sp57);
}

.pl-58 {
  padding-left: var(--sp58);
}

.ml-58 {
  margin-left: var(--sp58);
}

.pl-59 {
  padding-left: var(--sp59);
}

.ml-59 {
  margin-left: var(--sp59);
}

.pl-60 {
  padding-left: var(--sp60);
}

.ml-60 {
  margin-left: var(--sp60);
}

.pl-61 {
  padding-left: var(--sp61);
}

.ml-61 {
  margin-left: var(--sp61);
}

.pl-62 {
  padding-left: var(--sp62);
}

.ml-62 {
  margin-left: var(--sp62);
}

.pl-63 {
  padding-left: var(--sp63);
}

.ml-63 {
  margin-left: var(--sp63);
}

.pl-64 {
  padding-left: var(--sp64);
}

.ml-64 {
  margin-left: var(--sp64);
}

.pl-65 {
  padding-left: var(--sp65);
}

.ml-65 {
  margin-left: var(--sp65);
}

.pl-66 {
  padding-left: var(--sp66);
}

.ml-66 {
  margin-left: var(--sp66);
}

.pl-67 {
  padding-left: var(--sp67);
}

.ml-67 {
  margin-left: var(--sp67);
}

.pl-68 {
  padding-left: var(--sp68);
}

.ml-68 {
  margin-left: var(--sp68);
}

.pl-69 {
  padding-left: var(--sp69);
}

.ml-69 {
  margin-left: var(--sp69);
}

.pl-70 {
  padding-left: var(--sp70);
}

.ml-70 {
  margin-left: var(--sp70);
}

.pl-71 {
  padding-left: var(--sp71);
}

.ml-71 {
  margin-left: var(--sp71);
}

.pl-72 {
  padding-left: var(--sp72);
}

.ml-72 {
  margin-left: var(--sp72);
}

.pl-73 {
  padding-left: var(--sp73);
}

.ml-73 {
  margin-left: var(--sp73);
}

.pl-74 {
  padding-left: var(--sp74);
}

.ml-74 {
  margin-left: var(--sp74);
}

.pl-75 {
  padding-left: var(--sp75);
}

.ml-75 {
  margin-left: var(--sp75);
}

.pl-76 {
  padding-left: var(--sp76);
}

.ml-76 {
  margin-left: var(--sp76);
}

.pl-77 {
  padding-left: var(--sp77);
}

.ml-77 {
  margin-left: var(--sp77);
}

.pl-78 {
  padding-left: var(--sp78);
}

.ml-78 {
  margin-left: var(--sp78);
}

.pl-79 {
  padding-left: var(--sp79);
}

.ml-79 {
  margin-left: var(--sp79);
}

.pl-80 {
  padding-left: var(--sp80);
}

.ml-80 {
  margin-left: var(--sp80);
}

.pl-81 {
  padding-left: var(--sp81);
}

.ml-81 {
  margin-left: var(--sp81);
}

.pl-82 {
  padding-left: var(--sp82);
}

.ml-82 {
  margin-left: var(--sp82);
}

.pl-83 {
  padding-left: var(--sp83);
}

.ml-83 {
  margin-left: var(--sp83);
}

.pl-84 {
  padding-left: var(--sp84);
}

.ml-84 {
  margin-left: var(--sp84);
}

.pl-85 {
  padding-left: var(--sp85);
}

.ml-85 {
  margin-left: var(--sp85);
}

.pl-86 {
  padding-left: var(--sp86);
}

.ml-86 {
  margin-left: var(--sp86);
}

.pl-87 {
  padding-left: var(--sp87);
}

.ml-87 {
  margin-left: var(--sp87);
}

.pl-88 {
  padding-left: var(--sp88);
}

.ml-88 {
  margin-left: var(--sp88);
}

.pl-89 {
  padding-left: var(--sp89);
}

.ml-89 {
  margin-left: var(--sp89);
}

.pl-90 {
  padding-left: var(--sp90);
}

.ml-90 {
  margin-left: var(--sp90);
}

.pl-91 {
  padding-left: var(--sp91);
}

.ml-91 {
  margin-left: var(--sp91);
}

.pl-92 {
  padding-left: var(--sp92);
}

.ml-92 {
  margin-left: var(--sp92);
}

.pl-93 {
  padding-left: var(--sp93);
}

.ml-93 {
  margin-left: var(--sp93);
}

.pl-94 {
  padding-left: var(--sp94);
}

.ml-94 {
  margin-left: var(--sp94);
}

.pl-95 {
  padding-left: var(--sp95);
}

.ml-95 {
  margin-left: var(--sp95);
}

.pl-96 {
  padding-left: var(--sp96);
}

.ml-96 {
  margin-left: var(--sp96);
}

/* FONT FAMILY*/
.font-ubuntu {
  font-family: var(--ubuntu);
}

.font-montserrat {
  font-family: var(--montserrat);
}

.fs-base {
  font-size: var(--fsbase);
}

.fs-10 {
  font-size: var(--fs10);
}

.line-height-10 {
  line-height: 10px;
}

.fs-11 {
  font-size: var(--fs11);
}

.line-height-11 {
  line-height: 11px;
}

.fs-12 {
  font-size: var(--fs12);
}

.line-height-12 {
  line-height: 12px;
}

.fs-13 {
  font-size: var(--fs13);
}

.line-height-13 {
  line-height: 13px;
}

.fs-14 {
  font-size: var(--fs14);
}

.line-height-14 {
  line-height: 14px;
}

.fs-15 {
  font-size: var(--fs15);
}

.line-height-15 {
  line-height: 15px;
}

.fs-16 {
  font-size: var(--fs16);
}

.line-height-16 {
  line-height: 16px;
}

.fs-17 {
  font-size: var(--fs17);
}

.line-height-17 {
  line-height: 17px;
}

.fs-18 {
  font-size: var(--fs18);
}

.line-height-18 {
  line-height: 18px;
}

.fs-19 {
  font-size: var(--fs19);
}

.line-height-19 {
  line-height: 19px;
}

.fs-20 {
  font-size: var(--fs20);
}

.line-height-20 {
  line-height: 20px;
}

.fs-21 {
  font-size: var(--fs21);
}

.line-height-21 {
  line-height: 21px;
}

.fs-22 {
  font-size: var(--fs22);
}

.line-height-22 {
  line-height: 22px;
}

.fs-23 {
  font-size: var(--fs23);
}

.line-height-23 {
  line-height: 23px;
}

.fs-24 {
  font-size: var(--fs24);
}

.line-height-24 {
  line-height: 24px;
}

.fs-25 {
  font-size: var(--fs25);
}

.line-height-25 {
  line-height: 25px;
}

.fs-26 {
  font-size: var(--fs26);
}

.line-height-26 {
  line-height: 26px;
}

.fs-27 {
  font-size: var(--fs27);
}

.line-height-27 {
  line-height: 27px;
}

.fs-28 {
  font-size: var(--fs28);
}

.line-height-28 {
  line-height: 28px;
}

.fs-29 {
  font-size: var(--fs29);
}

.line-height-29 {
  line-height: 29px;
}

.fs-30 {
  font-size: var(--fs30);
}

.line-height-30 {
  line-height: 30px;
}

.fs-31 {
  font-size: var(--fs31);
}

.line-height-31 {
  line-height: 31px;
}

.fs-32 {
  font-size: var(--fs32);
}

.line-height-32 {
  line-height: 32px;
}

.fs-33 {
  font-size: var(--fs33);
}

.line-height-33 {
  line-height: 33px;
}

.fs-34 {
  font-size: var(--fs34);
}

.line-height-34 {
  line-height: 34px;
}

.fs-35 {
  font-size: var(--fs35);
}

.line-height-35 {
  line-height: 35px;
}

.fs-36 {
  font-size: var(--fs36);
}

.line-height-36 {
  line-height: 36px;
}

.fs-37 {
  font-size: var(--fs37);
}

.line-height-37 {
  line-height: 37px;
}

.fs-38 {
  font-size: var(--fs38);
}

.line-height-38 {
  line-height: 38px;
}

.fs-39 {
  font-size: var(--fs39);
}

.line-height-39 {
  line-height: 39px;
}

.fs-40 {
  font-size: var(--fs40);
}

.line-height-40 {
  line-height: 40px;
}

.fs-41 {
  font-size: var(--fs41);
}

.line-height-41 {
  line-height: 41px;
}

.fs-42 {
  font-size: var(--fs42);
}

.line-height-42 {
  line-height: 42px;
}

.fs-43 {
  font-size: var(--fs43);
}

.line-height-43 {
  line-height: 43px;
}

.fs-44 {
  font-size: var(--fs44);
}

.line-height-44 {
  line-height: 44px;
}

.fs-45 {
  font-size: var(--fs45);
}

.line-height-45 {
  line-height: 45px;
}

.fs-46 {
  font-size: var(--fs46);
}

.line-height-46 {
  line-height: 46px;
}

.fs-47 {
  font-size: var(--fs47);
}

.line-height-47 {
  line-height: 47px;
}

.fs-48 {
  font-size: var(--fs48);
}

.line-height-48 {
  line-height: 48px;
}

.fs-49 {
  font-size: var(--fs49);
}

.line-height-49 {
  line-height: 49px;
}

.fs-50 {
  font-size: var(--fs50);
}

.line-height-50 {
  line-height: 50px;
}

.fs-51 {
  font-size: var(--fs51);
}

.line-height-51 {
  line-height: 51px;
}

.fs-52 {
  font-size: var(--fs52);
}

.line-height-52 {
  line-height: 52px;
}

.fs-53 {
  font-size: var(--fs53);
}

.line-height-53 {
  line-height: 53px;
}

.fs-54 {
  font-size: var(--fs54);
}

.line-height-54 {
  line-height: 54px;
}

.fs-55 {
  font-size: var(--fs55);
}

.line-height-55 {
  line-height: 55px;
}

.fs-56 {
  font-size: var(--fs56);
}

.line-height-56 {
  line-height: 56px;
}

.fs-57 {
  font-size: var(--fs57);
}

.line-height-57 {
  line-height: 57px;
}

.fs-58 {
  font-size: var(--fs58);
}

.line-height-58 {
  line-height: 58px;
}

.fs-59 {
  font-size: var(--fs59);
}

.line-height-59 {
  line-height: 59px;
}

.fs-60 {
  font-size: var(--fs60);
}

.line-height-60 {
  line-height: 60px;
}

.fs-61 {
  font-size: var(--fs61);
}

.line-height-61 {
  line-height: 61px;
}

.fs-62 {
  font-size: var(--fs62);
}

.line-height-62 {
  line-height: 62px;
}

.fs-63 {
  font-size: var(--fs63);
}

.line-height-63 {
  line-height: 63px;
}

.fs-64 {
  font-size: var(--fs64);
}

.line-height-64 {
  line-height: 64px;
}

/* FONT WEIGHTS */
.font-bold {
  font-weight: var(--bold);
}

.font-semibold {
  font-weight: var(--semibold);
}

.font-regular {
  font-weight: var(--regular);
}

.font-light {
  font-weight: var(--light);
}

.font-700 {
  font-weight: 700;
}

.text-white {
  color: var(--contrast0);
}

.text-dark {
  color: var(--cloud900);
}

.text-brand-500 {
  color: var(--accent800);
}

.text-red-400 {
  color: var(--sunset500);
}