/* FONT FAMILY*/

.font-ubuntu {
  font-family: $ubuntu;
}

.font-montserrat {
  font-family: $montserrat;
}
.fs-base {
  font-size: $fsbase;
}

@for $i from 10 through 64 {
  .fs-#{$i} {
    font-size: var(--fs + $i);
  }
  .line-height-#{$i} {
    line-height: #{$i}px;
  }
}

/* FONT WEIGHTS */
.font-bold {
  font-weight: $bold;
}

.font-semibold {
  font-weight: $semibold;
}

.font-regular {
  font-weight: $regular;
}

.font-light {
  font-weight: $light;
}

.font-700 {
  font-weight: 700;
}
