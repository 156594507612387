/*
  Estilização para aplicar a identidade Flui no scrollbar nativo
*/

@mixin fluiScrollbar {
  /* for Chrome and Edge (chromium) */
  &::-webkit-scrollbar-track {
    border-radius: 6px;
    background-color: $cloud100;
  }
  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: $accent500;
  }

  /* for Firefox */
  scrollbar-color: $accent500 $cloud100;
  scrollbar-width: thin;

  /* for IE11 */
  scrollbar-face-color: $accent500;
  scrollbar-arrow-color: $accent500;
  scrollbar-track-color: $cloud100;
  scrollbar-shadow-color: $accent500;
  scrollbar-highlight-color: $accent500;
  scrollbar-3dlight-color: $accent500;
  scrollbar-darkshadow-Color: $cloud100;
}

.flui-scrollbar {
  @include fluiScrollbar;
}