/* Cores padronizadas pelo Flui Design System v2.0 */

/* ===== PRIMITIVE TOKENS ===== */

/* Accent */
$accent100: var(--accent100);
$accent200: var(--accent200);
$accent300: var(--accent300);
$accent400: var(--accent400);
$accent500: var(--accent500);
$accent600: var(--accent600);
$accent700: var(--accent700);
$accent800: var(--accent800);
$accent900: var(--accent900);
/* Aurora */
$aurora100: var(--aurora100);
$aurora200: var(--aurora200);
$aurora300: var(--aurora300);
$aurora400: var(--aurora400);
$aurora500: var(--aurora500);
$aurora600: var(--aurora600);
$aurora700: var(--aurora700);
$aurora800: var(--aurora800);
$aurora900: var(--aurora900);
/* Beach */
$beach100: var(--beach100);
$beach200: var(--beach200);
$beach300: var(--beach300);
$beach400: var(--beach400);
$beach500: var(--beach500);
$beach600: var(--beach600);
$beach700: var(--beach700);
$beach800: var(--beach800);
$beach900: var(--beach900);
/* Brand */
$brand100: var(--brand100);
$brand200: var(--brand200);
$brand300: var(--brand300);
$brand400: var(--brand400);
$brand500: var(--brand500);
$brand600: var(--brand600);
$brand700: var(--brand700);
$brand800: var(--brand800);
$brand900: var(--brand900);
/* cloud */
$cloud100: var(--cloud100);
$cloud200: var(--cloud200);
$cloud300: var(--cloud300);
$cloud400: var(--cloud400);
$cloud500: var(--cloud500);
$cloud600: var(--cloud600);
$cloud700: var(--cloud700);
$cloud800: var(--cloud800);
$cloud900: var(--cloud900);
/* Contrast */
$contrast0: var(--contrast0);
$contrast1000: var(--contrast1000);
/* Dawn */
$dawn100: var(--dawn100);
$dawn200: var(--dawn200);
$dawn300: var(--dawn300);
$dawn400: var(--dawn400);
$dawn500: var(--dawn500);
$dawn600: var(--dawn600);
$dawn700: var(--dawn700);
$dawn800: var(--dawn800);
$dawn900: var(--dawn900);
/* Desert */
$desert100: var(--desert100);
$desert200: var(--desert200);
$desert300: var(--desert300);
$desert400: var(--desert400);
$desert500: var(--desert500);
$desert600: var(--desert600);
$desert700: var(--desert700);
$desert800: var(--desert800);
$desert900: var(--desert900);
/* Green */
$green100: var(--green100);
$green200: var(--green200);
$green300: var(--green300);
$green400: var(--green400);
$green500: var(--green500);
$green600: var(--green600);
$green700: var(--green700);
$green800: var(--green800);
$green900: var(--green900);
/* Ocean */
$ocean100: var(--ocean100);
$ocean200: var(--ocean200);
$ocean300: var(--ocean300);
$ocean400: var(--ocean400);
$ocean500: var(--ocean500);
$ocean600: var(--ocean600);
$ocean700: var(--ocean700);
$ocean800: var(--ocean800);
$ocean900: var(--ocean900);
/* Pistachio */
$pistachio100: var(--pistachio100);
$pistachio200: var(--pistachio200);
$pistachio300: var(--pistachio300);
$pistachio400: var(--pistachio400);
$pistachio500: var(--pistachio500);
$pistachio600: var(--pistachio600);
$pistachio700: var(--pistachio700);
$pistachio800: var(--pistachio800);
$pistachio900: var(--pistachio900);
/* Purple */
$purple100: var(--purple100);
$purple200: var(--purple200);
$purple300: var(--purple300);
$purple400: var(--purple400);
$purple500: var(--purple500);
$purple600: var(--purple600);
$purple700: var(--purple700);
$purple800: var(--purple800);
$purple900: var(--purple900);
/* Rainbow */
$rainbow100: var(--rainbow100);
$rainbow200: var(--rainbow200);
$rainbow300: var(--rainbow300);
$rainbow400: var(--rainbow400);
$rainbow500: var(--rainbow500);
$rainbow600: var(--rainbow600);
$rainbow700: var(--rainbow700);
$rainbow800: var(--rainbow800);
$rainbow900: var(--rainbow900);
/* Red */
$red100: var(--red100);
$red200: var(--red200);
$red300: var(--red300);
$red400: var(--red400);
$red500: var(--red500);
$red600: var(--red600);
$red700: var(--red700);
$red800: var(--red800);
$red900: var(--red900);
/* Storm */
$storm100: var(--storm100);
$storm200: var(--storm200);
$storm300: var(--storm300);
$storm400: var(--storm400);
$storm500: var(--storm500);
$storm600: var(--storm600);
$storm700: var(--storm700);
$storm800: var(--storm800);
$storm900: var(--storm900);
/* Sunset */
$sunset100: var(--sunset100);
$sunset200: var(--sunset200);
$sunset300: var(--sunset300);
$sunset400: var(--sunset400);
$sunset500: var(--sunset500);
$sunset600: var(--sunset600);
$sunset700: var(--sunset700);
$sunset800: var(--sunset800);
$sunset900: var(--sunset900);
/* Sunshine */
$sunshine100: var(--sunshine100);
$sunshine200: var(--sunshine200);
$sunshine300: var(--sunshine300);
$sunshine400: var(--sunshine400);
$sunshine500: var(--sunshine500);
$sunshine600: var(--sunshine600);
$sunshine700: var(--sunshine700);
$sunshine800: var(--sunshine800);
$sunshine900: var(--sunshine900);
/* Twilight */
$twilight100: var(--twilight100);
$twilight200: var(--twilight200);
$twilight300: var(--twilight300);
$twilight400: var(--twilight400);
$twilight500: var(--twilight500);
$twilight600: var(--twilight600);
$twilight700: var(--twilight700);
$twilight800: var(--twilight800);
$twilight900: var(--twilight900);
/* Yellow */
$yellow100: var(--yellow100);
$yellow200: var(--yellow200);
$yellow300: var(--yellow300);
$yellow400: var(--yellow400);
$yellow500: var(--yellow500);
$yellow600: var(--yellow600);
$yellow700: var(--yellow700);
$yellow800: var(--yellow800);
$yellow900: var(--yellow900);
/* ===== STYLESHEETS ===== */
/* alert (yellow) */
$alert100: var(--alert100);
$alert200: var(--alert200);
$alert300: var(--alert300);
$alert400: var(--alert400);
$alert500: var(--alert500);
$alert600: var(--alert600);
$alert700: var(--alert700);
$alert800: var(--alert800);
$alert900: var(--alert900);
/* danger (red) */
$danger100: var(--danger100);
$danger200: var(--danger200);
$danger300: var(--danger300);
$danger400: var(--danger400);
$danger500: var(--danger500);
$danger600: var(--danger600);
$danger700: var(--danger700);
$danger800: var(--danger800);
$danger900: var(--danger900);
/* informative (purple) */
$informative100: var(--informative100);
$informative200: var(--informative200);
$informative300: var(--informative300);
$informative400: var(--informative400);
$informative500: var(--informative500);
$informative600: var(--informative600);
$informative700: var(--informative700);
$informative800: var(--informative800);
$informative900: var(--informative900);
/* neutral (cloud) */
$neutral100: var(--neutral100);
$neutral200: var(--neutral200);
$neutral300: var(--neutral300);
$neutral400: var(--neutral400);
$neutral500: var(--neutral500);
$neutral600: var(--neutral600);
$neutral700: var(--neutral700);
$neutral800: var(--neutral800);
$neutral900: var(--neutral900);
/* success (green) */
$success100: var(--success100);
$success200: var(--success200);
$success300: var(--success300);
$success400: var(--success400);
$success500: var(--success500);
$success600: var(--success600);
$success700: var(--success700);
$success800: var(--success800);
$success900: var(--success900);