@import "./theme";

// TODO: FIX bug
.flui-input-container .flui-input {
  -webkit-user-select: initial !important;
  user-select: initial !important;
}

/* TAGS */
* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  font-family: var(--ubuntu);
  font-size: var(--fsbase);
}

@media print {
  body {
    height: auto !important;
  }
}

a {
  text-decoration: none;
  color: var(--accent500);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--montserrat);
}

/* CUSTOM SELECTORS */

[role=button],
[role=link] {
  cursor: pointer;
}

/* GENERAL CLASSES */

.hidden-element { display: none; }

.no-margin { margin: 0; }
.no-padding { padding: 0; }

.wrapper-container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 var(--sp24);
}

.page-container {
  max-width: 1240px;
  margin: auto;
}

.internal-pages{
  background: $cloud100;
  padding-bottom: 40px;
}

.onlysr {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.grecaptcha-badge {
  display: none;
}

#pix-credentials-table-pdf {
  border: $sp1 solid $cloud300;
  border-radius: $sp8;
  padding: $sp16;

  .table {
    border-collapse: collapse;
    width: 100%;
    text-align: left;

    .col1 {
      width: 9%;
    }

    .col2 {
      width: 22%;
    }

    .col5 {
      width: 23%;
    }
    
    .col4,
    .col5 {
      width: 23%;
    }

    .head-row {
      border-bottom: $sp1 solid $cloud400;

      .head {
        padding: $sp24 $sp16;
        font-size: $fs14;
        font-family: $ubuntu;
        color: $cloud500;
      }
    }

    .row {
      border-top: $sp1 solid $cloud300;
      font-size: $fs14;

      .cell1,
      .cell2,
      .cell3 {
        padding: $sp24 $sp0 $sp24 $sp16;
        font-family: Arial, sans-serif;
        word-break: break-all;
        color: $cloud800;
      }

      .cell4,
      .cell5 {
        padding: $sp24 $sp0 $sp24 $sp16;
        font-family: Arial, sans-serif;
        font-weight: $bold;
        word-break: break-all;
        color: $green500;
      }
    }
  }
}

#ecommerce-credentials-table-pdf {
  border: $sp1 solid $cloud300;
  border-radius: $sp8;
  padding: $sp16;

  .table {
    border-collapse: collapse;
    width: 100%;
    text-align: left;

    .col1 {
      width: 38%;
    }

    .col2 {
      width: 0%;
    }

    .col3,
    .col4 {
      width: 31%;
    }

    .head-row {
      border-bottom: $sp1 solid $cloud500;

      .head {
        padding: $sp24 $sp16;
        font-size: $fs14;
        font-family: $ubuntu;
        color: $cloud600;
      }
    }

    .row {
      border-top: $sp1 solid $cloud300;
      font-size: $fs14;

      .cell1,
      .cell2 {
        padding: $sp24 $sp0 $sp24 $sp16;
        font-family: $ubuntu;
        word-break: break-all;
        color: $cloud900;
      }

      .cell3,
      .cell4 {
        padding: $sp24 $sp0 $sp24 $sp16;
        font-family: $ubuntu;
        font-weight: $bold;
        word-break: break-all;
        color: $green600;
      }
    }
  }
}