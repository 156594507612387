$sp0: var(--sp0);
$sp1: var(--sp1);
$sp2: var(--sp2);
$sp4: var(--sp4);
$sp8: var(--sp8);
$sp12: var(--sp12);
$sp16: var(--sp16);
$sp20: var(--sp20);
$sp24: var(--sp24);
$sp32: var(--sp32);
$sp40: var(--sp40);
$sp48: var(--sp48);
$sp56: var(--sp56);
$sp64: var(--sp64);
$sp72: var(--sp72);
$sp80: var(--sp80);
$sp88: var(--sp88);
$sp96: var(--sp96);
