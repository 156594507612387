/* Tipografia, Fonts e configurações padronizadas pelo Flui Design System */
/* https://www.figma.com/file/l6nCPIsB7jzktVs9hAK2pB/Flui?node-id=7420%3A9 */

/* FONT FAMILIES */
$montserrat: var(--montserrat);
$ubuntu: var(--ubuntu);

/* FONT WEIGHTS */
$bold: var(--bold);
$semibold: var(--semibold);
$regular: var(--regular);
$light: var(--light);

/* FONT SIZES - 16px based */
$fsbase: var(--fsbase);
$fs10: var(--fs10);
$fs12: var(--fs12);
$fs14: var(--fs14);
$fs16: var(--fs16);
$fs18: var(--fs18);
$fs20: var(--fs20);
$fs24: var(--fs24);
$fs28: var(--fs28);
$fs32: var(--fs32);
$fs40: var(--fs40);
$fs48: var(--fs48);
$fs56: var(--fs56);
$fs64: var(--fs64);
