@for $i from 1 through 96 {
  .p-#{$i} {
    padding: var(--sp + $i);
  }
  .m-#{$i} {
    margin: var(--sp + $i);
  }
}

@each $abbr, $name in ('t': 'top', 'r': 'right', 'b': 'bottom', 'l': 'left') {
  @for $i from 1 through 96 {
    .p#{$abbr}-#{$i} {
      padding-#{$name}: var(--sp + $i);
    }
    .m#{$abbr}-#{$i} {
      margin-#{$name}: var(--sp + $i);
    }
  }
}
